:root {
  font-size: 0.875em;
  line-height: 1.43em;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.chrome-picker.colorPicker > div:nth-child(2) > div:nth-child(2) {
  display: none !important;
}
.colorPicker{
  position: relative;
  z-index:3;
}

div.colorPicker>div.chrome-picker.colorPicker>div:nth-child(2)>div:nth-child(1)>div:nth-child(1){
  opacity: 0;
  width:6px !important;
}
div.colorPicker>div.chrome-picker.colorPicker>div:nth-child(2)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>div>div>div>div{
  width: 18px !important;
    height: 18px !important;
    border-radius: 18px !important;
}

.scaleRangeBar[type="range"] {
  -webkit-appearance: none;
  width: 313px;
  height: 6px;
  border-radius: 5px;
  background: #ececec;
  background-repeat: no-repeat;
  margin: 18px 0px;
  background-image: linear-gradient(#000, #000);
}

.scaleRangeBar[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
  background: #fff;
  cursor: pointer;
}

.scaleRangeBar[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.scaleRangeBar[type="range"]::-webkit-slider-thumb {
  transition: all 0.3s ease-in-out;
}


/* design the swatches  */
.swatchCircle {
  box-sizing: border-box;
  border: 3px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease-in;
  cursor: pointer;
  position: relative;
  display:block;
  z-index:5;
}

.swatchCircle:hover, .swatchCircleActive {
  transform: translateX(-10px);
}



.item {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}
/* Designing the Control Panel Container */
.controlPanelContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0em;
  position: absolute;
  width: 360px;
  min-height: 538px;
  right: 0px;
  top: 100px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 3px;
  right:24px;
  z-index: 4;
  padding-bottom:24px;
  box-sizing: border-box;
}
.controlPanelContainerHidden {
 min-height: unset;
 height:32px;
}

/* Display the tabs */
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0em;
  position: static;
  width: 360px;
  height: 24px;
  left: 1.57em;
  top: 1.71em;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0.52em 0em;
  z-index: 5;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0em;

  position: static;
  width: 74px;
  height: 20px;
  left: 0em;
  top: 0em;

  cursor: pointer;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 1.71em 1.71em 0px 1.57em;
}

.hexInput input {
  box-sizing: border-box;
  position: relative;
  padding: 0.5em 0.571em 0.357em;
  width: 236px;
  height: 28px;
  margin-left: 6em;
  top: -2.1em;
  background: #fafafa;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  font-family: Spartan;
  font-style: normal;
  font-weight: 500;
  color: #888888;
}

.rgbInput {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.rgbInput input {
  box-sizing: border-box;
  position: relative;
  padding: 0.5em 0.571em 0.357em;
  width: 75px;
  height: 28px;
  margin-left: 0.714em;
  left: 2.857em;
  top: -2.1em;
  background: #fafafa;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  font-family: Spartan;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #888888;
}
.hexInput > div,
.rgbInput > div {
  height: 0px;
}

.colorPalete {
  box-sizing: border-box;
  position: static;
  width: 24px;
  height: 24px;
  left: 0em;
  top: 0em;
  background: #ffffff;
  border: solid #303030;
  border-width: 0px;
  border-radius: 3px;
  flex: none;
  justify-content: center;
  order: 0;
  flex-grow: 0;
  margin: 0em 0.7143em 0.7143em 0.7143em;
  cursor: pointer;
}

#deletePresetColor {
  display: flex;
  position: absolute;
  margin-left: 23.43em;
  margin-top: 0em;
}
.filter {
  width: 63px;
  height: 20px;
  cursor: pointer;
  display:flex;
  justify-content: space-between;
  align-items: center;
}


.filterItems {
  display: block;
  border-radius: 3px;
  min-width: 155px;
  min-height: 132px;
  overflow-y: hidden;
  overflow-x: hidden;

  position: absolute;
  left: 14.29em;
  z-index: 1;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #303030;
}
.filterItems label {
  padding: 0px 16px;
  text-decoration: none;

  position: relative;

  top: 21px;
  left: 38px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.filterItems input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  top: -1.357em;
  margin: 0em;
  border-color: #ececec !important;
  width: 16px;
  height: 16px;

  margin-left: 1.143em;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  opacity: 0.3;
}
.filterItems input:checked {
  opacity: 1;
}

.selectedValue {
  display: block;
  border-radius: 3px;
  width:40px;
 
  min-height: 72px;
  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  left: 1.714em;
  z-index: 1;

  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  cursor: pointer;
    font-family: "Spartan";
      font-style: normal;
      font-weight: 500;
      font-size: 0.857rem;
      line-height: 1.14em;
  display: flex;
  flex-direction: column;
}
.selectedValue > div {
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height:calc(72px / 3);
}


.selectedOption {
  background: #ececec;

}
.hideEditedPaletteName {
  display: none;
}

.editedPaletteName {
  display: block;
}

.hidePaletteNameContainer {
  display: none;
}
.paletteNameContainer {
  display: block;
  width: 312px;
  height: 28px;
}
.hideDeletedContainer {
  display: none;
}
.deletedContainer {
  display: block;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0em;
  gap: 12px;

  height: 40px;
  position: absolute;
  right: 24px;
  top:48px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.previewMode{
  opacity: 0;
  pointer-events: none !important;
  transform: scale(0.8);
}

.previewModeRulerTop{
  opacity: 0;
  pointer-events: none !important;
  transform: translateY(-24px);
}

.previewModeRulerLeft{
  opacity: 0;
  pointer-events: none !important;
  transform: translateX(-24px);
}

.controlPanelContainerHolder{
  transition:all 0.1s ease-in;

}